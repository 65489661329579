import React, { useMemo } from 'react';
import { useTranslate } from 'react-admin';
import { Accordion, AccordionDetails, AccordionSummary, makeStyles, Switch, Typography } from '@material-ui/core';
import { useField } from 'react-final-form';
import { SelectInput } from '../../../base/components/ra/inputs';
import { getBackofficeEnv } from '../../../services/util';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '0 24px',
    "& [class*='expanded']": {
      transform: 'rotate(0)',
    },
    '&+*': {
      padding: '0 16px',
    },
  },
  wrapper: {
    marginBottom: '24px !important',
    boxShadow: 'none',
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '8px !important',
    '&:before': {
      background: 'transparent',
    },
  },
}));

const AutocompleteFreeSpins = props => {
  const classes = useStyles();
  const {
    input: {
      value: brandSettings, onChange,
    },
  } = useField('brandSettings');
  const { COMPLETE_ROUND_PERIOD_OPTIONS } = getBackofficeEnv();
  const t = useTranslate();
  const choices = useMemo(
    () => {
      const options = COMPLETE_ROUND_PERIOD_OPTIONS.split(',').map(j => Number(j));
      return options.map(item => ({
        id: item,
        name: t('resources.brand.fields.autocompleteIn', {
          minutes: item,
        }),
      }));
    },
    [t],
  );

  return (
    <Accordion
      className={classes.wrapper}
      elevation={3}
      expanded={!!brandSettings?.roundOpenPeriod}
      onChange={e => {
        e.stopPropagation();
        onChange({
          roundOpenPeriod: e.target.checked ? choices[0].id : 0,
        });
      }}
    >
      <AccordionSummary
        className={classes.root}
        expandIcon={(
          <Switch
            checked={!!brandSettings?.roundOpenPeriod}
            color="primary"
          />
        )}
      >
        <Typography>{t('resources.brand.fields.allowAutoCompletingFreeSpins')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <SelectInput
          label="resources.brand.fields.autocompleteFreeSpins"
          source="brandSettings.roundOpenPeriod"
          choices={choices}
          fullWidth
          {...props}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default AutocompleteFreeSpins;
